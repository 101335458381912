import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    main_page: null, // 0
    main_path: null,
    sub_page: null,
    user_data: null,
    center_coordi: null,
    zoom_level: null,
    cart: [],
    carts: [],
    current_route: null,
    notice: {
      ver: 20211010,
      old_ver: null,
      check: false,
      content: '내용없음',
    },
    current_position: null,
    home_state: 'list',
    balance: 0,
    product: null,
    shopDetail: null,
    myGifts: null, 
    guid: null, 
    currentItem: null,
  },

  getters: {
    getMainPage: state => {
      //   console.log('getMainPage  main_page : ', state.main_page);
      return state.main_page;
    },
    getMainPath: state => {
      //   console.log('getMainPage  main_path : ', state.main_path);
      return state.main_path;
    },
    getSubPage: state => {
      //   console.log('getSubPage  sub_page : ', state.sub_page);
      return state.sub_page;
    },
    getUserData: state => {
      // console.log('getUserData  user_data : ', state.user_data);
      return state.user_data;
    },
    getCenterCoordi: state => {
      //   console.log('getCenterCoordi  center_coordi : ', state.center_coordi);
      return state.center_coordi;
    },
    getZoomLevel: state => {
      //   console.log('getZoomLevel  zoom_level : ', state.zoom_level);
      return state.zoom_level;
    },
    getCart: state => {
      //   console.log('getCart  product : ', state.carts);
      return state.carts;
    },
    getCurrentRoute: state => {
      // console.log('getCurrentRoute : ', state.current_route);
      return state.current_route;
    },
    getNotice: state => {
      // console.log('getNotice : ', state.notice);
      return state.notice;
    },
    getCurrentPosition: state => {
      // console.log('current_position : ', state.current_position);
      return state.current_position;
    },
    getHomeState: state => {
      // console.log('home_state : ', state.home_state);
      return state.home_state;
    },
    getBalance: state => {
      // console.log('balance Store : ', state.balance);
      return state.balance;
    },
    getProduct: state => {
      // console.log('balance Store : ', state.balance);
      return state.product;
    },
    getShopDetail: state => {
      // console.log('balance Store : ', state.balance);
      return state.shopDetail;
    },
    getMyGifts: state => {
      // console.log('balance Store : ', state.balance);
      return state.myGifts;
    },
    getGuid: state => {
      // console.log('balance Store : ', state.balance);
      return state.guid;
    },
    getCurrentItem: state => {
      // console.log('balance Store : ', state.balance);
      return state.currentItem;
    },
  }, // getters 

  mutations: {
    resetStateCarts(state) {
      // Merge rather than replace so we don't lose observers
      // https://github.com/vuejs/vuex/issues/1118
      state.carts = []
    },
    setMainPage(state, payload) {
      // console.log('setMainPage  main_page .....................: ', payload.main_page);
      state.main_page = payload.main_page;
    },
    setMainPath(state, payload) {
      // console.log('setMainPath  main_path : ', payload.main_path);
      state.main_path = payload.main_path;
    },
    setSubPage(state, payload) {
      //   console.log('setSubPage  sub_page : ', payload.sub_page);
      state.sub_page = payload.sub_page;
    },
    setCenterCoordi(state, payload) {
      //   console.log('setCenterCoordi  center_coordi : ', payload.center_coordi);
      state.center_coordi = payload.center_coordi;
    },
    setZoomLevel(state, payload) {
      //   console.log('setZoomLevel  zoom_level : ', payload.zoom_level);
      state.zoom_level = payload.zoom_level;
    },
    setCart(state, payload) {
      //   console.log('setCart  state.cart.length : ', state.carts.length);
      if (payload.product == null) {
        state.carts = []
      } else {
        const item = payload.product;
        state.carts.push(item)
      }
    },
    cancelProduct(state, payload) {
      //   console.log('cancelProduct  : ', payload.index);
      let idx = payload.index
      let products = state.carts
      for (var i = 0; i < products.length; i++) {
        if (i === idx) {
          products.splice(i, 1);
        }
      }
      state.carts = products
    },
    setCurrentRoute(state, payload) {
      //   console.log('setCurrentRoute   : ', payload.route);
      state.current_route = payload.route;
    },
    setUserData(state, payload) {
      // console.log('setUserData   : ', payload.userData);
      state.user_data = payload.userData;
    },
    setNotice(state, payload) {
      // console.log('setNotice   : ', payload.notice);
      state.notice = payload.notice;
    },
    setCurrentPosition(state, payload) {
      // console.log('current_position   : ', payload.current_position);
      state.current_position = payload.current_position;
    },
    setHomeState(state, payload) {
      // console.log('home_state   : ', payload.home_state);
      state.home_state = payload.home_state;
    },
    setBalance(state, payload) {
      // console.log('balance   : ', payload.balance);
      state.balance = payload.balance;
    },
    setProduct(state, payload) {
      // console.log('Product   : ', payload.product);
      state.product = payload.product;
    },
    setShopDetail(state, payload) {
      // console.log('Product   : ', payload.product);
      state.shopDetail = payload.shopDetail;
    },
    setMyGifts(state, payload) {
      // console.log('Product   : ', payload.product);
      state.myGifts = payload.myGifts;
    },
    setGuid(state, payload) {
      // console.log('Product   : ', payload.product);
      state.guid = payload.guid;
    },
    setCurrentItem(state, payload) {
      // console.log('Product   : ', payload.product);
      state.currentItem = payload.currentItem;
    },
  }, // mutations 

  actions: {
  },
  modules: {
  },
  plugins: [
    createPersistedState()
  ]
})
