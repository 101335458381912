// import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors'
import '@mdi/font/css/materialdesignicons.css'


//svgPath: mdiAccount

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'md',
    },
    theme: {
        themes: {
          light: {
            primary: '#fe9900', // amond : #5EB4F9 , #FFC107  --> orange #EF6C00--> #fe9900
            primary2: '#ff5112', // amond : #5EB4F9 , #FFC107  --> orange #EF6C00--> #fe9900
            secondary: colors.red.lighten4, // #FFCDD2
            accent: colors.indigo.base, // #3F51B5
          },
        },
    },


});
