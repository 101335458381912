import axios from 'axios';

// axios.defaults.baseURL = 'http://localhost:9090/v1/' 
axios.defaults.baseURL = 'http://onethecon.com/v1/'; // onethecon

axios.interceptors.request.use(async function (config) {
    //config.headers.token = VueCookies.get('token');
    //config.headers.refresh_token = VueCookies.get('refresh_token');

    //console.log(config);
    return config;
}, function (error) {
    return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
    return response;
}, async function (error) {
    //console.log('에러일 경우', error.config);
    const errorAPI = error.config;
    if(error.response.data.status===401 && errorAPI.retry===undefined){
        errorAPI.retry = true;
        //console.log('토큰이 이상한 오류일 경우');
        //await refreshToken();
        return await axios(errorAPI);
    }
    return Promise.reject(error);
});


export default axios;
