import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from './service/axios';
import VueCookies from 'vue-cookies';
import 'vuetify/dist/vuetify.min.css'
import i18n from './i18n';
import googleIcon from 'material-design-icons-iconfont/dist/material-design-icons.css';
import { ValidationProvider } from 'vee-validate';
import firebase from "firebase";
import _ from 'lodash'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
// import mitt from 'mitt'

// const emitter = mitt();



Vue.use(require('vue-cookies'))
Vue.config.productionTip = false;
Vue.prototype.axios = axios;
// Vue.prototype.emitter = emitter;
Vue.use(vuetify)
Vue.use(VueCookies);
Vue.component('ValidationProvider', ValidationProvider);
Vue.$cookies.config('1d')
Vue.prototype.$EventBus = new Vue();

Vue.filter('removeNullProps',function(object) {
  // sorry for using lodash and ES2015 arrow functions :-P
  return _.reject(object, (value) => value === null)
})

// window.Kakao.init("4a3461a486bda6bc314e726e5d6ec59e"); //cycon javascript key
// window.Kakao.init("f2476e6b246bdf2bcdcdbc011ee4d9c6"); //onethecon javascript key
// window.Kakao.init("a01c66e77df115b558609d046f34cb94"); //cycon admin
// window.Kakao.init("f2476e6b246bdf2bcdcdbc011ee4d9c6"); //onethecon

// import naver from 'vue-naver-maps';
// Vue.use(naver, {
//   clientID: '68cbdmi47a',
//   useGovAPI: false, //공공 클라우드 API 사용 (선택)
//   subModules:'' // 서브모듈 (선택)
// });

var firebaseConfig = {
  apiKey: "AIzaSyAYqfmTBOPNaxSYQWpDHKNhEYyHiCxji-U",
  authDomain: "cycon-a9f12.firebaseapp.com",
  projectId: "cycon-a9f12",
  storageBucket: "cycon-a9f12.appspot.com",
  messagingSenderId: "631538699696",
  appId: "1:631538699696:web:950b372685889878d7da75",
  measurementId: "G-RMWR87LDYQ"
};

// var firebaseConfig = {
//     apiKey: "AIzaSyAYqfmTBOPNaxSYQWpDHKNhEYyHiCxji-U",
//     authDomain: "cycon-a9f12.firebaseapp.com",
//     projectId: "cycon-a9f12",
//     storageBucket: "cycon-a9f12.appspot.com",
//     messagingSenderId: "631538699696",
//     appId: "1:631538699696:web:6addf5979e22e336d7da75",
//     measurementId: "G-52Q8Y0KHKZ"
// };

firebase.initializeApp(firebaseConfig);

new Vue({
  router,
  store,
  vuetify,
  VueCookies,
  i18n,
  googleIcon,
  // axios,
  render: h => h(App)
}).$mount('#app')
