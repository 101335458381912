import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router);

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/auth',
            name: 'auth',
            component: () => import('../views/pages/auth/Auth')
        },
        {
            path: '/',
            component: () => import("../views/Index"),
            children: [
                {
                    name: 'home',
                    path: '/home/:gift', 
                    component: () => import("../views/pages/Home"),
                    // props: true,
                },
                {
                    name: 'sale',
                    path: '/sale/:id',
                    component: () => import("../views/pages/Sale"),
                    // props: true,
                },
                {
                    name: 'favorite',
                    path: '/favorite',
                    component: () => import("../views/pages/Favorite"),
                },
                {
                    name: 'partner',
                    path: '/partner',
                    component: () => import("../views/pages/Partner"),
                },
                {
                    name: 'myinfo',
                    path: '/myinfo',
                    component: () => import("../views/pages/MyInfo"),
                },
                {
                    name: 'success',
                    path: '/success',
                    component: () => import("../views/pages/payments/success"),
                },
                {
                    name: 'chargesuccess',
                    path: '/charge/success',
                    component: () => import("../views/pages/payments/ChargeSuccess"),
                },
            ]
        },
        {
            path: '/detail',
            component: () => import("../views/Detail"),
            children: [
                {
                    name: 'ledger',
                    path: '/detail/ledger',
                    props: true,
                    component: () => import("../components/wallet/Ledger"),
                },
                {
                    name: 'gift_preview',
                    path: '/detail/gifti_preview',
                    props: true,
                    component: () => import("../views/pages/gift_info/GiftiPreview"),
                },
                {
                    name: 'product_detail',
                    path: '/detail/product_detail',
                    props: true,
                    component: () => import("../views/pages/gift_info/ProductDetail"),
                },
                {
                    name: 'shop_detail',
                    path: '/detail/shopDetail',
                    props: true,
                    component: () => import("../views/pages/shop_info/ShopDetail"),
                },
                {
                    name: 'user_info',
                    path: '/detail/userinfo',
                    component: () => import("../views/pages/my_info/UserInfo"),
                },
                {
                    name: 'history',
                    path: '/detail/history',
                    component: () => import("../views/pages/my_info/History"),
                },
                {
                    name: 'delhistory',
                    path: '/detail/delhistory',
                    component: () => import("../views/pages/my_info/DelHistory"),
                },
                {
                    name: 'notice',
                    path: '/detail/notice',
                    component: () => import("../views/pages/my_info/Notice"),
                },
                {
                    name: 'termservice',
                    path: '/detail/termservice',
                    component: () => import("../views/pages/my_info/TermService"),
                },
                {
                    name: 'servicecenter',
                    path: '/detail/servicecenter',
                    component: () => import("../views/pages/my_info/ServiceCenter"),
                },
                {
                    name: 'brand',
                    path: '/gift_info/brand',
                    component: () => import("../views/pages/gift_info/Brand"),
                },
                {
                    name: 'withdraw',
                    path: '/withdraw/withdraw',
                    component: () => import("../views/pages/withdraw/WithDraw"),
                },
                {
                    name: 'addmoney',
                    path: '/payments/AddMoney',
                    component: () => import("../views/pages/payments/AddMoney"),
                },
                {
                    name: 'sendmoney',
                    path: '/payments/SendMoney',
                    component: () => import("../views/pages/payments/SendMoney"),
                },
                {
                    name: 'delhistory',
                    path: '/admin/DelHistory',
                    component: () => import("../views/pages/admin/DelHistory"),
                },
                {
                    name: 'verifyUser',
                    path: '/verifyUser',
                    component: () => import("../components/VerifyUser"),
                },
                {
                    name: 'sellGift',
                    path: '/sellGift/:gift',
                    component: () => import("../views/pages/Sale"),
                },
                {
                    name: 'sendGift',
                    path: '/sendGift/:gift',
                    component: () => import("../views/pages/subPage/SendGift"),
                },
            ]
        },
        
        {
            path: '/sign',
            component: () => import("../views/Sign"),
            children: [
                {
                    name: 'login',
                    path: '/sign/login',
                    component: () => import("../views/pages/sign/SignMix"),
                },
                {
                    name: 'signUp',
                    path: '/sign/signUp/:flag',
                    component: () => import("../views/pages/sign/SignMix"),
                },

            ]
        },
        {
            path: '/getOneNotice',
            name: 'noticePage', 
            props: true,
            component: () => import('@/components/modal/NoticePage')
        },
    ]
});

