<template>
  <v-fade-transition mode="out-in">
    <keep-alive include="index">
      <router-view />
    </keep-alive>
  </v-fade-transition>
</template>

<script>
export default {
  name: "home",

  data() {
    return {};
  },

  created() {
    // location.reload()
  },

  mounted() {
    console.log("App.vue mounted....");
    // window.location.reload(true)
    this.$store.commit({ type: "setMainPage", main_page: 0 });
    this.$store.commit({ type: "setHomeState", home_state: "map" });
  }, // mounted
};
</script>

<style>
@import url(http://fonts.googleapis.com/earlyaccess/nanumgothic.css);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Nanum Gothic", sans-serif;
  letter-spacing: -0.02rem;
}
</style>
